import { graphql, Link, useStaticQuery } from 'gatsby';
import { gsap } from 'gsap';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { FluxDnaSettings } from '../components/Footer/FluxDnaSettings';
import '../components/Header/Header.scss';
import MainSiteInnerNav from '../components/Header/MainSiteInnerNav';

const PageTemplate = (props) => {
  // Menu
  const menuElement = useRef();
  const menuElementBooking = useRef();
  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  const fluxFooterSetting = FluxDnaSettings();
  const [isImage, setisImage] = useState(null);

  const query = useStaticQuery(graphql/* GraphQL */ `
    query {
      wp {
        fluxDnaSettings {
          fluxDna {
            travelEnquiryBgimage {
              altText
              mediaItemUrl
              imgixImage {
                fluid(
                  maxWidth: 800
                  maxHeight: 1024
                  imgixParams: { w: 800, h: 1024, fit: "crop" }
                ) {
                  srcWebp
                }
              }
              uri
              title
            }
            travelEnquiryLogo {
              altText
              mediaItemUrl
              uri
              title
            }
          }
          pageTitle
          pageSlug
          fluxDna {
            royalPortfolio {
              cta {
                target
                title
                url
              }
              primaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
              secondaryLogo {
                altText
                mediaItemUrl
                uri
                title
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    document.body.classList.remove('menu-open');
    menuElement.current = gsap.timeline({
      paused: true,
      onStart: () => {
        document.body.classList.add('menu-open');
        setMenuOpen(true);
      },
      onReverseComplete: () => {
        document.body.classList.remove('menu-open');
        setMenuOpen(false);
      },
    });

    menuElement.current.to('.nav-container', {
      duration: 0.6,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      right: 0,
      ease: 'Power3.InOut',
    });

    menuElement.current.from(
      '.mainNav__col--right, .header-social-icon',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );
    menuElement.current.from(
      '.navMenuBottom, .menu-ct-info',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );

    menuElementBooking.current = gsap.timeline({
      paused: true,
      onStart: () => {
        document.body.classList.add('booking-open');
      },
      onReverseComplete: () => {
        document.body.classList.remove('booking-open');
        setMenuOpenStatus(false);
      },
    });
    menuElementBooking.current.to('.booking-container', {
      duration: 0.6,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      right: 0,
      ease: 'Power3.InOut',
    });
    menuElementBooking.current.from(
      '.booking-info-container',
      { stagger: 0.1, opacity: 0, y: 20, ease: 'Power3.InOut' },
      '-=0'
    );
  }, []);
  useEffect(() => {
    menuOpenStatus ? menuElement.current.play() : menuElement.current.reverse();
    menuOpenBooking
      ? menuElementBooking.current.play()
      : menuElementBooking.current.reverse();
  }, [menuOpenStatus, menuOpenBooking]);

  const headerDataList = query.wp.fluxDnaSettings.fluxDna;
  const callback = (image) => {
    if (image) {
      setisImage(image);
    }
  };
  return (
    <>
      <section className="thank-you text-center">
        <div className="flex flex-wrap">
          <div className="lg:w-7/12 w-full closemenu">
            <div
              className="xl:px-80 lg:px-40
             px-20 lg:py-40 pb-20 pt-20 relative h-screen overflow-y-auto enquiry-left"
            >
              <div className="enquiry-header mb-50">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={
                          headerDataList.royalPortfolio.primaryLogo.mediaItemUrl
                        }
                        alt="Logo"
                        className="m-auto lg:w-[149px] w-[120px] desktop-logo"
                      />
                    </Link>
                    <Link to="/">
                      <img
                        src={
                          headerDataList.royalPortfolio.secondaryLogo
                            .mediaItemUrl
                        }
                        alt="Logo"
                        className="m-auto lg:w-[149px sticky-logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="scroll-form-main thankYou-content flex flex-col justify-center items-center gap-y-6">
                <div className="top-info flex justify-center flex-wrap items-center">
                  <h1 className="text-black-500 lg:text-34">Thank You</h1>
                </div>
                <div className="px-120 desktop:px-20">
                  Our reservations team in Cape Town will be on hand to assist
                  throughout the booking process. The team will be in touch as
                  soon as possible and can assist with booking charter flights,
                  transfers, day tours, spa treatments, wine tastings and other
                  activities.
                  <br></br>
                  <br></br>
                  In the unlikely event that you do not hear from us within 24
                  hours, please check your junk/spam folders and please feel
                  free to call us on{' '}
                  <a className="text-gold" href="tel:+27216715502">
                    +27 (0) 21 671 5502
                  </a>
                  .
                </div>
                <Link to="/" className="button button-transparent">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 w-full">
            <div className="img h-full">
              <img
                src={
                  headerDataList.travelEnquiryBgimage.imgixImage.fluid.srcWebp
                }
                alt={headerDataList.travelEnquiryBgimage.altText}
                width="800"
                height="1024"
                className="w-full h-screen object-cover"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageTemplate;
export const Head = () => <title>Thank You | The Royal Portfolio</title>;
